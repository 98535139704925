import { BaseDto } from '@/shared/dtos/base-dto';

export class objetivos_medicion_vista_tabla extends BaseDto {
    public medicion !: string;
    public obj_medicion !: string;
    public fecha_alta_obj!:Date;
    public fecha_conseguido!:Date;
    public conseguido !: boolean;
    public id_paciente !: number;
 } 
