import { store } from '@/store/store';
import { objetivos_medicion } from '@/shared/dtos/Objetivos/objetivos_medicion';
import { objetivos_medicion_vista_tabla } from '@/shared/dtos/Objetivos/objetivos_medicion_vista_tabla';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'objetivos_medicionModule',
    store,
    dynamic: true
})
class objetivos_medicionModule extends VuexModule {
    public objetivos_mediciones: objetivos_medicion[] = [];
    public objetivos_medicion: objetivos_medicion = new objetivos_medicion();
    public objetivos_medicion_vista: objetivos_medicion_vista_tabla[] = [];;

    @Action({ commit: 'onGetobjetivos_mediciones' })
    public async getobjetivos_mediciones() {
        return await ssmHttpService.get(API.objetivos_medicion);
    }

    @Action({ commit: 'onGetobjetivos_mediciones_vista' })
    public async getobjetivos_mediciones_paciente(id: number) {
        return await ssmHttpService.get(API.objetivos_medicion + '/Paciente/' + id);
    }

    @Action({ commit: 'onGetobjetivos_medicion' })
    public async getobjetivos_medicion(id: number) {
        return await ssmHttpService.get(API.objetivos_medicion + '/' + id);
    }

    @Action
    public async guardarobjetivos_medicion(objetivos_medicion: objetivos_medicion) {
        /*toJson() no existe pero existe en la extends de BaseDto*/
        await ssmHttpService.post(API.objetivos_medicion, objetivos_medicion.toJson());
    }

    @Action
    public async modificarobjetivos_medicion(objetivos_medicion: objetivos_medicion) {
        await ssmHttpService.put(API.objetivos_medicion + '/' + objetivos_medicion.id, objetivos_medicion);
    }

    @Action
    public async eliminarobjetivos_medicion(objetivos_medicion: objetivos_medicion) {
        await ssmHttpService.delete(API.objetivos_medicion + '/' + objetivos_medicion.id, null, false);
    }

    @Mutation
    public onGetobjetivos_mediciones(res: objetivos_medicion[]) {
        this.objetivos_mediciones = res ? res.map((x) => new objetivos_medicion(x)) : [];
    }

    @Mutation
    public onGetobjetivos_mediciones_vista(res: objetivos_medicion_vista_tabla[]) {
        this.objetivos_medicion_vista = res ? res.map((x) => new objetivos_medicion_vista_tabla(x)) : [];
    }

    @Mutation
    public onGetobjetivos_medicion(res: objetivos_medicion) {
        this.objetivos_medicion = new objetivos_medicion(res);
    }

}
export default getModule(objetivos_medicionModule);